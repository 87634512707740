import './App.scss';

import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';

import Navigation from './Navigation';
import Footer from './Footer';

const Home = lazy(()=>import('./pages/Home'));
const About = lazy(()=>import('./pages/About'));
const Gallery = lazy(()=>import('./pages/Gallery'));
const Contact = lazy(()=>import('./pages/Contact'));

export default function App() {
	return (
		<BrowserRouter>
			<Navigation />
			<Suspense fallback={<Spinner animation="border" className="d-flex mx-auto my-5" />}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/gallery" element={<Gallery />} />
					<Route path="/contact" element={<Contact />} />
				</Routes>
			</Suspense>
			<Footer />
		</BrowserRouter>
	);
}
