import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { ReactComponent as LogoNav } from './images/logo-optimised.svg';

export default function Navigation(){
	let location = useLocation();
	return(
		<Navbar collapseOnSelect expand="lg" variant="light" bg="white" className="d-flex" sticky="top">
			<Container>
					<Navbar.Brand as={Link} to="/" className="flex-grow-1 py-0 d-flex align-items-center">
						<LogoNav className="me-3" style={{height: "50px", width: "50px"}}/>
						The Letter Tree
					</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-nav" />
				<Navbar.Collapse id="navbar-nav" className="flex-grow-0">
					<Nav>
						<Nav.Link as={Link} to="/" active={location.pathname==="/"} className="me-lg-5 py-3 py-lg-2 text-center">Home</Nav.Link>
						<Nav.Link as={Link} to="/about" active={location.pathname.startsWith("/about")} className="mx-lg-5 py-3 py-lg-2 text-center">About</Nav.Link>
						<Nav.Link as={Link} to="/gallery" active={location.pathname.startsWith("/gallery")} className="mx-lg-5 py-3 py-lg-2 text-center">Gallery</Nav.Link>
						<Nav.Link as={Link} to="/contact" active={location.pathname.startsWith("/contact")} className="ms-lg-5 py-3 py-lg-2 text-center">Contact</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}