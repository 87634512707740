import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

import { FaPhoneAlt, FaEnvelope, FaFacebookF, FaInstagram, FaPinterest } from 'react-icons/fa';

export default function Footer(){
	return(
		<Container fluid className="border-top">
			<Container className="py-5">
				<Row>
					<Col lg className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
						<Link to="/" className="text-decoration-none py-2">Home</Link>
						<Link to="/about" className="text-decoration-none py-2">About</Link>
						<Link to="/gallery" className="text-decoration-none py-2">Gallery</Link>
						<Link to="/contact" className="text-decoration-none py-2">Contact</Link>
					</Col>
					<Col lg className="d-flex flex-column align-items-center justify-content-center">
						<a href="tel:+447521059431" className="py-2 text-decoration-none"><FaPhoneAlt className="me-2"/>07521 059 431</a>
						<a href="mailto:info@thelettertree.co.uk" target="_blank" rel="noreferrer" className="py-2 text-decoration-none"><FaEnvelope className="me-2"/>info@thelettertree.co.uk</a>
					</Col>
					<Col lg className="d-flex justify-content-around align-items-center flex-lg-column align-items-lg-end justify-content-lg-center">
						<a href="https://www.facebook.com/TheLetterTree67/" target="_blank" rel="noreferrer" className="py-2"><FaFacebookF size="1.5em" /></a>
						<a href="https://www.instagram.com/thelettertree/" target="_blank" rel="noreferrer" className="py-2"><FaInstagram size="1.5em" /></a>
						<a href="https://www.pinterest.co.uk/TheLetterTree67/" target="_blank" rel="noreferrer" className="py-2"><FaPinterest size="1.5em" /></a>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}